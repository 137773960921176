<template>
  <div class="popup-container" :class="{'is-active': modelValue}">
    <div class="popup">
      <div class="popup-inner">
        <header class="popup-header">
          <h3 class="title">Hot Tip 공유 목록</h3>
        </header>
        <div class="popup-content">
          <article class="popup-section">
            <div class="kb-table kb-table-bordered">
              <table>
                <colgroup>
                  <col style="width:10%;">
                  <col style="width:15%;">
                  <col style="width:auto;">
                  <col style="width:10%;">
                  <col style="width:25%;">
                </colgroup>
                <thead>
                <tr>
                  <th><span class="th-title">공유일시</span></th>
                  <th><span class="th-title">구분</span></th>
                  <th><span class="th-title">게시글명</span></th>
                  <th><span class="th-title">작성자</span></th>
                  <th><span class="th-title">공유대상</span></th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="isLoading">
                  <td colspan="5"><LoadingDiv /></td>
                </tr>
                <template v-else-if="items.length > 0">
                  <tr v-for="(item, idx) in items" :key="idx">
                    <td><span class="td-text">{{timestampToDateFormat(item.regDt,'yyyy.MM.dd')}}</span></td>
                    <td><span class="td-text">{{item.boardTitle}}</span></td>
                    <td><a class="kb-mouse-cursor" @click.stop="clickPost(idx)"><strong class="td-text text-gold">{{item.postTitle}}</strong></a></td>
                    <td><span class="td-text">{{item.lrnerNm}}</span></td>
                    <td><span class="td-text">{{item.shareTrgtLrnerNmLst}}</span></td>
                  </tr>
                </template>
                <tr v-else class="search-container">
                  <td colspan="5" class="result-empty">
                    <img src="@/assets/lxp/images/common/img_empty.png" alt="">
                    <p class="text">공유내역이 없습니다.</p>
                  </td>
                </tr>
                </tbody>
              </table>
              <CommonPaginationFront v-if="!isLoading" :paging="paging" :page-func="pagingFunc" />
            </div>
          </article>
        </div>
        <div class="popup-buttons">
          <button class="kb-btn kb-btn-outline kb-btn-lg" @click="closeModal"><span class="text">닫기</span></button>
        </div>
      </div>
      <div class="popup-close">
        <button class="kb-btn-popup-close" @click="closeModal"><i class="icon-popup-close">팝업닫기</i></button>
      </div>
    </div>
  </div>
</template>

<script>
import CommonPaginationFront from "@/components/CommonPaginationFront.vue";
import LoadingDiv from "@/components/common/LoadingDiv.vue";
import {learnHottipShareListSetup} from "@/assets/js/modules/learn/learn-hottip-share-list-setup";

export default {
  name: 'HotTipShareList',
  components: {CommonPaginationFront, LoadingDiv},
  props: {
    modelValue: Boolean
  },
  emits: ['update:modelValue'],
  setup: learnHottipShareListSetup
}
</script>