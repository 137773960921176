<template>
  <LxpMobileHeader title="내가 공유한 Hot Tip">
    <template v-slot:left>
      <div class="util util-back">
        <a class="util-actions util-actions-back" @click="closeModal">
          <i class="icon-history-back"></i>
        </a>
      </div>
    </template>
  </LxpMobileHeader>
  <main class="kb-main" id="kb-support">
    <div class="main-content main-component">
      <div class="view-contents">
        <article class="content-section">
          <div v-if="isLoading" class="section-content">
            <LoadingDiv />
          </div>
          <div v-else-if="items.length > 0" class="section-content">
            <div v-for="(item,idx) in items" :key="idx" class="content-item" :class="{'bd-clear pd-top-clear': idx == 0}">
              <a @click.stop="clickPost(idx)"><h5 class="b-title">{{ item.postTitle }}</h5></a>
              <ul class="desc-type-title">
                <li>
                  <h6 class="text-nowrap">구분</h6>
                  <p>{{ item.boardTitle }}</p>
                </li>
                <li>
                  <h6 class="text-nowrap">작성자</h6>
                  <p>{{ item.lrnerNm }}</p>
                </li>
                <li>
                  <h6 class="text-nowrap">공유대상</h6>
                  <p>{{ item.shareTrgtLrnerNmLst }}</p>
                </li>
                <li>
                  <h6 class="text-nowrap">공유일자</h6>
                  <p>{{ timestampToDateFormat(item.regDt, 'yyyy.MM.dd') }}</p>
                </li>
              </ul>
            </div>
          </div>
          <div v-else class="section-content">
            <div class="content-item">
              <div class="result-empty">
                <img src="@/assets/lxp/images/common/img_empty.png" alt="">
                <p class="text">공유내역이 없습니다.</p>
              </div>
            </div>
          </div>
          <CommonPaginationFront v-if="!isLoading" :paging="paging" :page-func="pagingFunc" />
        </article>
      </div>
    </div>
  </main>
</template>

<script>
import LxpMobileHeader from "@/views/layout/lxp/header/mobile/LxpMobileHeader.vue";
import CommonPaginationFront from "@/components/CommonPaginationFront.vue";
import LoadingDiv from "@/components/common/LoadingDiv.vue";
import {learnHottipShareListSetup} from "@/assets/js/modules/learn/learn-hottip-share-list-setup";
export default {
  name: 'HotTipShareListMobile',
  components: {LxpMobileHeader, CommonPaginationFront, LoadingDiv},
  props: {
    modelValue: Boolean
  },
  emits: ['update:modelValue'],
  setup: learnHottipShareListSetup
}
</script>