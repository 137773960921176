<template>
  <HrdCloudHistoryMobileKbTube v-if="isMobile && uploadModal.isActive" v-model="uploadModal.isActive" :init-upload-modal-active="true" />
  <HotTipShareListMobile v-else-if="isMobile && toggle.shareModal" v-model="toggle.shareModal" />
  <main v-else class="kb-main" id="kb-hottip-main">
    <HotTipHeader />
    <div v-if="!toggle.opinionModal" class="main-content main-component hottip-content">
      <section class="hottip-main-header">
        <div class="section-area banner">
          <swiper class="hrdcloud-swiper"
                  :modules="modules"
                  effect="fade"
                  :pagination="{
                      el: '.swiper-pagination',
                      clickable: true
                  }"
                  :autoplay="{
                      delay: 30000,
                      disableOnInteraction: true,
                      stopOnLast: true,
                  }"
                  :navigation="{
                      prevEl: '.swiper-btn-prev',
                      nextEl: '.swiper-btn-next'
                  }"
          >
            <swiper-slide v-for="(item, index) in banners" :key="index" @click.stop="goToBannerUrl(item)">
              <HrdCloudBannerVideo v-if="item.type === 'video'" :video-src="isMobile ? item.mobiThumb : item.thumb" />
              <CommonImage v-else :img-src="isMobile ? item.mobiThumb : item.thumb" />
            </swiper-slide>
            <div class="swiper-pagination"></div>
            <div v-if="!isMobile" class="swiper-buttons">
              <div class="swiper-btn-prev"><i class="icon-arrow" /></div>
              <div class="swiper-btn-next"><i class="icon-arrow" /></div>
            </div>
          </swiper>
        </div>
        <!-- 모바일 공지사항 -->
        <div v-if="isMobile" class="section-area notice">
          <div class="d-flex align-items-center">
            <swiper :modules="modules"
                    :initial-slide="0"
                    :slides-per-view="1"
                    :loop="false"
                    :autoplay="{delay: 3000, disableOnInteraction: false}">
              <swiper-slide v-for="notice in hotTipNoticeList" :key="notice.comPostSn">
                <div class="d-flex" @click.stop="moveToHotTipNotice(notice.boardSecuKey)">
                  <span class="text text-truncate">{{timestampToDateFormat(notice.regDt, 'yyyy.MM.dd')}} {{notice.postTitle}}</span>
                </div>
              </swiper-slide>
            </swiper>
            <button class="kb-btn kb-btn-xxs kb-btn-secondary" @click.stop="moveToHotTipNotice(null)"><span class="text text-dark">전체보기</span></button>
          </div>
        </div>
        <div class="section-area my">
          <div class="my-info">
            <div class="avatar"><ProfileImg :target="session"/></div>
            <div v-if="isMobile" class="d-flex flex-column justify-content-center h-100">
              <h4 class="name">{{ session.lrnerNm }}</h4>
              <p class="team">{{ session.jbgdNm }} {{ session.jbpsNm }} {{ session.deptNm }}</p>
            </div>
            <template v-else>
              <h4 class="name">{{ session.lrnerNm }}</h4>
              <p class="team">{{ session.deptNm }}</p>
            </template>
          </div>
          <div class="my-service">
            <div class="kb-btn-area mb-lg-3">
              <button class="kb-btn kb-btn-writing" @click="writeToggle = !writeToggle">
                <span class="text">글쓰기</span>
                <i class="icon-writing" />
              </button>
              <div class="kb-form-dropdown" :class="[{'is-active': writeToggle},{'kb-btn-area': isMobile}]">
                <div class="kb-form-dropdown-options">
                  <div class="dropdown-option">
                    <ul class="dropdown-option-list">
                      <li class="dropdown-option-item kb-mouse-cursor" v-if="hottipMy">
                        <a class="dropdown-option-link" @click.stop="moveToBoardWrite('my')">
                          <span class="dropdown-option-text">{{hottipMy.boardTitle}}</span>
                        </a>
                      </li>
                      <li class="dropdown-option-item kb-mouse-cursor" v-if="hottipHq">
                        <a class="dropdown-option-link" @click.stop="moveToBoardWrite('hq')">
                          <span class="dropdown-option-text">{{hottipHq.boardTitle}}</span>
                        </a>
                      </li>
                      <li class="dropdown-option-item kb-mouse-cursor" v-if="hottipBiz">
                        <a class="dropdown-option-link" @click.stop="moveToBoardWrite('biz')">
                          <span class="dropdown-option-text">{{hottipBiz.boardTitle}}</span>
                        </a>
                      </li>
                      <li class="dropdown-option-item kb-mouse-cursor">
                        <a class="dropdown-option-link" @click.stop="clickHotTubeUpload">
                          <span class="dropdown-option-text">Hot Tube 등재</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <router-link :to="{name:'HotTipSearch',query:{'type':'my'}}" :class="isMobile ? 'kb-btn kb-btn-wide kb-btn-outline kb-btn-xs mt-2':'link'">
              <span class="text">나의 Hot Tip</span><i v-if="!isMobile" class="icon-arrow"></i>
            </router-link>
            <router-link :to="{name:'HotTipSearch',query:{'isLike':'Y'}}" :class="isMobile ? 'kb-btn kb-btn-wide kb-btn-outline kb-btn-xs mt-2':'link'">
              <span class="text">내가 좋아요한 Hot Tip</span><i v-if="!isMobile" class="icon-arrow"></i>
            </router-link>
            <button :class="isMobile ? 'kb-btn kb-btn-wide kb-btn-outline kb-btn-xs mt-2':'link'" @click="toggle.shareModal = !toggle.shareModal">
              <span class="text">내가 공유한 Hot Tip</span><i v-if="!isMobile" class="icon-arrow"></i>
            </button>
            <button v-if="isMobile" :class="isMobile ? 'kb-btn kb-btn-wide kb-btn-outline kb-btn-xs mt-2':'link'" @click="toggle.opinionModal = !toggle.opinionModal">
              <span class="text">Hot Tip 개선의견</span>
            </button>
          </div>
        </div>
      </section>
      <!-- PC 공지사항 -->
      <section v-if="!isMobile" class="hottip-notice">
        <HotTipBoardPreview board-id="hottip-notice" custom-title="공지사항" :custom-board-list="hotTipNoticeList" :show-notice="true"/>
      </section>
      <section class="hottip-board">
        <HotTipBoardPreview board-id="hottip-my" :custom-board-list="hotTipMyPreviewList" />
        <HotTipBoardPreview custom-title="우수 Tip 모아보기" :custom-board-list="hotTipBestList" :red-background="true" />
        <HotTipBoardPreview board-id="hottip-hq" :custom-board-list="hotTipHqPreviewList" />
        <HotTipBoardPreview board-id="hottip-biz" :custom-board-list="hotTipBizPreviewList" />
      </section>
      <section v-if="hottipCates.length > 0" :class="{'section-area' : isMobile}">
        <div v-if="isMobile" class="section-header pb-3">
          <h3 class="title">업무별 Hot Tip</h3>
        </div>
        <div class="section-body hottip-category">
          <template v-for="(category,idx) in hottipCates" :key="idx">
            <div class="category-box" @click="clickSearch(idx)">
              <div class="image" :category="category.cateNm.replaceAll(' ','')"></div>
              <span class="title">{{category.cateNm}}</span>
            </div>
          </template>
        </div>
      </section>
      <section class="section-area">
        <div class="section-header pb-3">
          <h3 class="title">Hot Tube</h3>
          <span v-if="!isMobile" class="title-sub kb-mouse-cursor" @click="moveToHotTubeList">전체보기</span>
        </div>
        <template v-if="hotTubeList.length > 0">
          <div class="section-body">
            <HrdCloudSwiper :items="hotTubeList" :session="session" />
          </div>
          <div v-if="isMobile" class="section-bottom">
            <button class="kb-btn kb-btn-wide kb-btn-outline kb-btn-xs" @click="moveToHotTubeList"><span class="text">전체보기</span></button>
          </div>
        </template>
        <LoadingDiv v-else />
      </section>
      <div v-if="isMobile" class="visitor-info">
        <span class="text text-center" v-html="hotTipManagerInfo"></span>
      </div>
    </div>
    <KbTubeUpload v-if="!isMobile && uploadModal.isActive" v-model="uploadModal"/>
    <HotTipShareList v-if="!isMobile" v-model="toggle.shareModal" />
  </main>
  <HotTipMobileOpinion v-if="toggle.opinionModal" v-model="toggle.opinionModal" :title="title"/>
</template>

<script>
import {onMounted, onUnmounted, ref, computed, reactive, watch} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import {Autoplay, Pagination, Navigation} from 'swiper';
import HrdCloudBannerVideo from '@/components/hrdcloud/HrdCloudBannerVideo.vue';
import CommonImage from "@/components/common/CommonImage.vue";
import HotTipHeader from "@/components/prep/HotTipHeader.vue";
import LoadingDiv from "@/components/common/LoadingDiv.vue";
import ProfileImg from "@/components/common/ProfileImg.vue";
import HotTipBoardPreview from "@/components/prep/HotTipBoardPreview.vue";
import HrdCloudSwiper from "@/components/hrdcloud/HrdCloudSwiper.vue";
import KbTubeUpload from "@/components/history/hrdcloud/KbTubeUpload.vue";
import HrdCloudHistoryMobileKbTube from '@/components/history/mobile/HrdCloudHistoryMobileKbTube';
import {MUT_TOGGLE_CONCENTRATION_MODE} from "@/store/modules/hrdcloud/hrdcloud";
import {getPostList, goToView, goToWrite} from "@/assets/js/modules/board/board-common";
import {getCheckItems, timestampToDateFormat} from "@/assets/js/util";
import {getHrdCloudList} from "@/assets/js/modules/hrd/hrd-common";
import {
  getBestHotTipList,
  getHotTipBoardCateList,
  getHotTipBoardMst, getHotTipMainPreviewList, hotTipManagerInfo,
  hotTubeParams,
} from "@/assets/js/modules/learn/learn-hot-tip";
import {banerDivCdDcds, banerExpsrLocCdDcds, getBanerList} from "@/assets/js/modules/baner/common-baner";
import HotTipMobileOpinion from "@/components/prep/mobile/HotTipMobileOpinion.vue";
import HotTipShareList from "@/components/prep/HotTipShareList.vue";
import HotTipShareListMobile from "@/components/prep/mobile/HotTipShareListMobile.vue";

export default {
  name: 'HotTipMain',
  components: {
    HotTipMobileOpinion,
    Swiper, SwiperSlide, CommonImage, HrdCloudBannerVideo, HotTipHeader, LoadingDiv, ProfileImg, HotTipBoardPreview, HrdCloudSwiper, KbTubeUpload, HrdCloudHistoryMobileKbTube, HotTipShareList, HotTipShareListMobile},
  setup() {
    const store = useStore();
    const router = useRouter();
    const session = computed(() => store.state.auth.session);
    const isMobile = computed(() => store.state.auth.isMobile);
    const boardMstCount = computed(() => store.state.boardmst.objects.length);
    const toggle = reactive({
      opinionModal: false,
      shareModal: false,
    })
    const title = 'Hot Tip 개선의견';

    // Hot Tip 배너
    const banners = ref([]);
    const getBanners = () => {
      getBanerList({banerDivCdDcd: banerDivCdDcds.baner, banerExpsrLocCdDcd: banerExpsrLocCdDcds.hotTip['main']}, banners);
    }
    const goToBannerUrl = (item) => {
      if(item.banerUrl && item.banerUrl.length > 0){
        window.location.href = item.banerUrl;
      }
    }

    // Hot Tip 검색
    const search = reactive({
      word: null,
      boardCateSns: [],
    });
    const clickSearch = (idx) => {
      if(idx != null){
        search.boardCateSns = hottipCates.value[idx].boardCateSns;
        search.word = null;
        router.push({name: 'HotTipSearch', query: {...search, type: hottipCates.value[idx].cateNm}});
      }else{
        search.boardCateSns = [];
        router.push({name: 'HotTipSearch', query: search});
      }
    }

    // 게시판 & 카테고리 정보
    const writeToggle = ref(false);
    const hottipNotice = computed(() => getHotTipBoardMst('hottip-notice'));
    const hottipMy = computed(() => getHotTipBoardMst('hottip-my'));
    const hottipMyCates = computed(() => getHotTipBoardCateList('hottip-my'));
    const hottipHq = computed(() => getHotTipBoardMst('hottip-hq'));
    const hottipHqCates = computed(() => getHotTipBoardCateList('hottip-hq'));
    const hottipBiz = computed(() => getHotTipBoardMst('hottip-biz'));
    const hottipCates = ref([]);

    const getHottipCategoryList = () => {
      if(hottipMyCates.value.length > 0){
        hottipMyCates.value.forEach(cate => {
          if(cate.boardCateSn){
            if(hottipCates.value.filter(x => x.cateNm === cate.cateNm).length > 0){
              hottipCates.value.find(x => x.cateNm === cate.cateNm).boardCateSns.push(cate.boardCateSn);
            }else{
              hottipCates.value.push({'cateNm':cate.cateNm, 'boardCateSns': [cate.boardCateSn]});
            }
          }
        })
      }
      if(hottipHqCates.value.length > 0){
        hottipHqCates.value.forEach(cate => {
          if(cate.boardCateSn){
            if(hottipCates.value.filter(x => x.cateNm === cate.cateNm).length > 0){
              hottipCates.value.find(x => x.cateNm === cate.cateNm).boardCateSns.push(cate.boardCateSn);
            }else{
              hottipCates.value.push({'cateNm':cate.cateNm, 'boardCateSns': [cate.boardCateSn]});
            }
          }
        })
      }
    }

    const moveToBoardWrite = (div) => {
      if(div === 'my'){
        goToWrite(session.value.lrnerId, hottipMy);
      }else if(div === 'hq'){
        goToWrite(session.value.lrnerId, hottipHq);
      }else if(div === 'biz'){
        goToWrite(session.value.lrnerId, hottipBiz);
      }
    }

    // 미리보기 정보
    const hotTipMyPreviewList = ref([]);
    const hotTipHqPreviewList = ref([]);
    const hotTipBizPreviewList = ref([]);
    // 우수 Tip 모아보기
    const hotTipBestList = ref([]);
    // 공지사항
    const hotTipNoticeList = ref([]);

    const getHotTipMainList = () => {
      if(hottipNotice.value && hotTipNoticeList.value.length === 0) {
        getPostList({boardMstSn: hottipNotice.value.boardMstSn, pageNo: 1, pageSize: 3}, hotTipNoticeList, null, [], null);
      }
      if(hotTipBestList.value.length === 0){
        getBestHotTipList().then((data) => {hotTipBestList.value = data});
      }
      if(hotTipMyPreviewList.value.length === 0){
        getHotTipMainPreviewList('hottip-my').then((data) => {hotTipMyPreviewList.value = data;});
      }
      if(hotTipHqPreviewList.value.length === 0){
        getHotTipMainPreviewList('hottip-hq').then((data) => {hotTipHqPreviewList.value = data;});
      }
      if(hotTipBizPreviewList.value.length === 0){
        getHotTipMainPreviewList('hottip-biz').then((data) => {hotTipBizPreviewList.value = data;});
      }
    }

    const moveToHotTipNotice = (boardSecuKey) => {
      if(boardSecuKey){
        goToView(boardSecuKey, hottipNotice.value.boardId);
      }else{
        router.push({name:'BoardMst',params:{'boardId':hottipNotice.value.boardId},query:{'hisback':1}});
      }
    }

    // Hot Tube 정보
    const uploadModal = reactive({
      isActive: false,
      toggle: () => {
        uploadModal.isActive = !uploadModal.isActive;
      },
    });
    const hotTubeList = ref([]);
    const getHotTubeList = async () => {
      const res = await getHrdCloudList({...hotTubeParams, pageSize: 10});
      hotTubeList.value = getCheckItems(res);
    }

    const moveToHotTubeList = () => {
      router.push({name: 'HrdCloud', query: hotTubeParams});
    }

    const clickHotTubeUpload = () => {
      uploadModal.toggle();
      writeToggle.value = false;
    }

    // 핫팁 게시판 총 4개: 'hottip-my','hottip-hq','hottip-biz','hottip-notice'
    watch(() => boardMstCount.value, () => {
      if(boardMstCount.value === 4){
        if(hottipMy.value && hottipHq.value){
          getHottipCategoryList();
        }
        getHotTipMainList();
      }
    })

    onMounted(() => {
      // 배경색 변경
      store.commit(`hrdcloud/${MUT_TOGGLE_CONCENTRATION_MODE}`, { 'mode' : 'fill'});
      getBanners();
      getHotTipMainList();
      getHottipCategoryList();
      getHotTubeList();
    })

    onUnmounted(() => {
      // 배경색 변경
      store.commit(`hrdcloud/${MUT_TOGGLE_CONCENTRATION_MODE}`, { 'mode' : ''});
    })

    return {
      session,
      isMobile,
      banners,
      modules: [Pagination, Navigation, Autoplay],
      search,
      writeToggle,
      hottipMy,
      hottipHq,
      hottipBiz,
      hottipCates,
      hotTipMyPreviewList,
      hotTipHqPreviewList,
      hotTipBizPreviewList,
      hotTipNoticeList,
      hotTipBestList,
      hotTubeList,
      uploadModal,
      toggle,
      hotTipManagerInfo,
      goToBannerUrl,
      clickSearch,
      moveToBoardWrite,
      moveToHotTipNotice,
      moveToHotTubeList,
      clickHotTubeUpload,
      timestampToDateFormat,
      title
    }
  }
}
</script>