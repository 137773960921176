import {onMounted, reactive, ref, watch} from "vue";
import {useStore} from "vuex";
import {getItems, getPaging, lengthCheck, setParams, timestampToDateFormat} from "@/assets/js/util";
import {ACT_GET_BOARD_ACT_LOG_LIST} from "@/store/modules/board/board-mst";
import {hotTipBoardIds} from "@/assets/js/modules/learn/learn-hot-tip";
import {goToView} from "@/assets/js/modules/board/board-common";

export const learnHottipShareListSetup = (props, {emit}) => {
    const store = useStore();
    const items = ref([]);
    const paging = reactive({pageNo:1, pageSize: 5, totalCount: 0});
    const isLoading = ref(true);

    const pagingFunc = (pageNo) => {
        paging.pageNo = pageNo;
        getShareList();
    }

    const closeModal = () => {
        paging.pageNo = 1;
        emit('update:modelValue', false);
    }

    const clickPost = (idx) => {
        goToView(items.value[idx].boardSecuKey, items.value[idx].boardId);
    }

    const getShareList = () => {
        store.dispatch(`boardmst/${ACT_GET_BOARD_ACT_LOG_LIST}`, {boardIds: hotTipBoardIds.join(','), pageNo: paging.pageNo, pageSize: paging.pageSize}).then(res => {
            if(lengthCheck(res)){
                items.value = getItems(res);
                setParams(paging, getPaging(res));
            }else{
                items.value = [];
                paging.totalCount = 0;
            }
        }).finally(() => {isLoading.value = false;})
    }

    watch(() => props.modelValue, () => {
        if(props.modelValue){
            isLoading.value = true;
            getShareList();
        }
    })

    onMounted(() => {
        if(props.modelValue) {
            getShareList();
        }
    })

    return {
        items,
        paging,
        isLoading,
        pagingFunc,
        closeModal,
        clickPost,
        timestampToDateFormat,
    }
}